import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledMainContainer = styled.main`
  counter-reset: section;

  .breadcrumb {
    margin-bottom: 0px;
    font-weight: bold;
    text-align: left;
  }

  .column {
    flex-direction: column;
    flex: 1;
    margin: 5px 10px;

    @media (max-width: 768px) {
      flex: auto;
      flex-direction: row;
      justify-content: center;
    }
  }

  #header {
    font-weight: bold;
  }

  #gamearea {
    border: 5px solid blue;
    background-color: #96ff06;
  }

  #continue {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
  }
`;

const ClickerPage = () => {
  const [position, setPosition] = useState({ left: '100px', top: '50px' });
  const [clicks, setClicks] = useState(0);
  const [highScore, setHighScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(10000);
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    if (isRunning && timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 10);
      }, 10);

      return () => {
        clearInterval(timerId);
      };
    } else {
      setIsRunning(false);
    }
  }, [isRunning, timeLeft]);

  const reset = () => {
    setIsRunning(false);
    setTimeLeft(10000);
    if (highScore < clicks) {
      setHighScore(clicks);
    }
    setClicks(0);
  };

  const formatTime = timeInMilliseconds => {
    const minutes = Math.floor(timeInMilliseconds / 60000);
    const seconds = Math.floor((timeInMilliseconds % 60000) / 1000);
    const milliseconds = (timeInMilliseconds % 1000) / 10;
    return `${minutes}:${seconds.toString().padStart(2, '0')}.${milliseconds
      .toString()
      .padStart(2, '0')}`;
  };

  const getRandomPosition = () => {
    const maxX = window.innerWidth - 200; // Adjust as needed
    const maxY = window.innerHeight - 200; // Adjust as needed
    const randomLeft = Math.floor(Math.random() * maxX);
    const randomTop = Math.floor(Math.random() * maxY);
    setPosition({ left: `${randomLeft}px`, top: `${randomTop}px` });
    setClicks(prevNumber => prevNumber + 1);
    if (!isRunning) {
      setIsRunning(true);
    }
  };

  return (
    <StyledMainContainer className="fillHeight">
      <head>
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <title>Swift Clicker</title>

        {/* <!-- this line is to include the FontAwesome icon set --> */}
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.10.2/css/all.min.css"
        />

        {/* <!-- this line is to include Bootstrap formatting --> */}
        <link
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
          rel="stylesheet"
          integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
          crossOrigin="anonymous"></link>
      </head>
      <div className="container-fluid">
        <div className="row">
          <div className="column">
            <span className="breadcrumb">
              <span className="arrow">&larr; </span>
              <Link to="/extra">Back to Extras</Link>
            </span>
          </div>
          <div className="column">
            <h3>Time: {formatTime(timeLeft)}</h3>
          </div>
          <div className="column">
            <h1 id="header">Quicker Clicker</h1>
          </div>
          <div className="column">
            <h3>Clicks: {clicks}</h3>
          </div>
          <div className="column">
            <h3>High Score: {highScore}</h3>
          </div>
        </div>
        <div id="gamearea" style={{ width: '100%', minHeight: '90vh' }}>
          {timeLeft === 0 && (
            <div id="continue">
              <button
                style={{ position: 'center', fontWeight: 'bold', fontSize: '50px' }}
                onClick={reset}>
                Continue
              </button>
            </div>
          )}
          {timeLeft > 0 && (
            <button
              style={{ ...position, position: 'relative', fontSize: '50px' }}
              onClick={getRandomPosition}>
              <span role="img" aria-labelledby="jsx-a11y/accessible-emoji">
                😜
              </span>
            </button>
          )}
        </div>
      </div>
    </StyledMainContainer>
  );
};

ClickerPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default ClickerPage;
